<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
    <div class='transition' :style='getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 12pt; font-weight: bold;width: 100%;'>AsRun Monitor 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 9pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		       
		        <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			
			    <GSelectSimple :options="stations" style='width:100%' v-model="stationId" @changed='stationChanged'/>
			    
			    <br/>
			    <span class='SVcontrolLabel'>Date:</span><br/>
			    <InputDatePick :maxDate="new Date()" :auto="false" @change="dateChanged" v-model="activeDate"/>     

		        <br/>
		        
			    <span class='SVcontrolLabel'>2nd Timezone:</span><br/>
			    <GSelect placeholder='choose second timezone' :options="timezones" style='width:100%' v-model="selectedTimezone" @input='tzChanged'>
			          
			    </GSelect>
			    
			    <div class="BMSection" :style='getBGStyle2()'>
		              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER:</b></span>
		        </div>
		        
		        <span class='dontWrap smallText bold'>States:</span>
			    <GSelect :options="allStates" style='font-size: 9pt;' v-model="selectedStates" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
			    <span class='dontWrap smallText bold'>Grid/Programs:</span>
			    <GSelect :options="allPrograms" style='font-size: 9pt;' v-model="selectedPrograms" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
		        <span class='dontWrap smallText bold'>Clients:</span>
			    <GSelect :options="allClients" style='font-size: 9pt;' v-model="selectedClients" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
		        <span class='dontWrap smallText bold'>Products:</span>
			    <GSelect :options="allProducts" style='font-size: 9pt;' v-model="selectedProducts" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
		        <span class='dontWrap smallText bold'>Copies:</span>
			    <GSelect :options="allCopies" style='font-size: 9pt;' v-model="selectedCopies" :multiple="true" @input="processChange()">  
		        </GSelect>

				<button :disabled='allPrograms.length==0 || allClients.length==0' class='button myButton' @click="resetFilter()">Clear</button>
				
		        <br/>
		        
			    
			    <span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
			    <hr/>
			    <!--
			    <button :disabled='days.length>2' class='button myButton' @click="addDay('chart')">Show Chart</button>
				-->
				<div  class="BMSection" :style='getBGStyle2()'>
		              <span class='BMSectionDetail dontWrap90 smallText' ><b>ACTIONS</b></span>
		        </div>
			    <button class='button myButton' @click="reload()">Reload</button>
			    <button :disabled='!dataDay.gridAds' class='button myButton' @click="reco()">Reconcile</button>


		    </div>

 	</div>
	<div class='transition uploadArea' style='float: top; position: relative;' id='uploader' 
	 		@drop="dropFile($event, 0, 0, 'uploader');"
		    @dragenter.prevent="hoverLine( 'uploader')"
		    @dragleave.prevent="leaveLine( 'uploader')"		        
			@dragover.prevent="hoverLine( 'uploader')"

	> 
		
		<RunDown v-if="dataDay.gridAds" style='float:left; width: 55%;'
 			:stations=stations
 			:user=user
 			:fontSize=fontSize
 			:selectedIN=columnsRD
 			:lookForLine=lookForLine
 			:updateLine=updateLine
 			:bookingStatusList=bookingStatusList
 			@userUpdated=userUpdated
 			@setTypeForSpots='setTypeForSpots'
 			@findInLog=findInLog
 			:selectedPrograms=selectedPrograms
 			:selectedStates=selectedStates
 			:selectedClients=selectedClients
 			:selectedBreaks=selectedBreaks
 			:selectedProducts=selectedProducts
 			:selectedCopies=selectedCopies
 			:updatedSpots=updatedSpots
 			:showEmptyBreaks="false"
 			:data=dataDay
 			:station=station
 			:date=activeDate 
 			:stateName=dataDay.status.shortname
 			:dayId="'Day 1'"
 			@closeDay=closeDay></RunDown>
 			
	    <RunDownAsRun v-if="data.lines" 
	    	:lookForSpot=lookForSpot 
	    	@findSpot=findSpot
	    	@markSpot=markSpot
	    	:data="data" 
	    	style='float:left; width: 45%;'/>
	    
	    <div v-else style='padding-top: 50pt; display: block; text-align: center; vertical-align: middle; align-items: center; justify-content: center; height: 100%; width: 100; border: 2pt solid #fcd;'>
	      <span style='color: #aaa;'>drop asrun file</span>
	     </div>
     </div>
	<GConfirm ref='confirm'/>
 </div>
</template>	
<script>
import {HTTP, HTTPMP, asrAPI, userAPI, opsAPI, bngAPI, fwAPI, invAPI, setReload, showError, myLocale} from '@/variables.js';
import {store, restore} from '@/runDown.js';
import { getAppStyle, setDarkMode, getBGStyle2, initAppMode } from '@/AppStyle.js';
import {printTimeOpt, printTimeHHMM, printTime } from '@/basicTimeFN.js';
import InputTime from '@/components/inputElements/InputTime2';

import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import RunDownAsRun from '@/components/asrun/RunDownAsRun';
import RunDown from '@/components/asrun/RunDown';
import GSelectSimple from '@/components/GSelectSimple';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import GConfirm from '@/components/GConfirm';
import GSelect from '@/components/misc/GSelect';
import InputDatePick from '@/components/inputElements/InputDatePick3';

import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW_RECO',
   
  components : {
	  GSelect, 'app-switch': Switch, InputDatePick, GConfirm, GSelectSimple, RunDownAsRun, RunDown
  },
  data () {
    return {
      stationId: 0,
      station: {},
      stations: [],
      bookingStatusList: [],
      lookForSpot: {},
      lookForLine: {},
      updateLine: {},
      tz: {},
	  user: {},
      noOfDays: 0,
      fontSize: 10,
      dayId: "",
      days: [ ],
      type: [ ],
      showDangling: [],
      loadingActive: false,
      selectedTimezone: {},
      selectedStation: {},
      tz2: {},
      diff: 0,
      timezones: [],
      timezoneId: 0,
      activeDate: {},
      dataDay: {},
      data: {},
      dayStatus: {},
      clipboard: [],
      update: [],
      controlsRight: false,
      updatedSpots: [],
      clbId: "",
      counter: 0,
      columnsCB: "",
      columnsRD: "",
      widthCalculated: 100,
      //
      allPrograms: [],
      selectedPrograms: [],
      allCopies: [],
      selectedCopies: [],
      allClients: [],
      selectedClients: [],
      allBreaks: [],
      selectedBreaks: [],
      allProducts: [],
      selectedProducts: [],
      allStates: [],
      selectedStates: [],
      getBGStyle2
      }
  },
  methods: {
    myLocale() { return myLocale();},
    copy(c) 
    {
        c.time = new Date().getTime();
    	return c;
    },
    closeDay(day)
 	{
 	    const index = this.days.indexOf(day);
		if (index > -1) {
		   this.days.splice(index, 1);
		}
		if ( day == this.clbId )
		{
			this.clbId = "";
		}
		this.dayId = this.days[ this.days.length-1];
    },
    addDay( )
    {
    	
    },
    findInLog(spot)
    {
    	this.$set( this, "lookForSpot", spot);
    },
    findSpot( line)
    {
        this.$set( this, "lookForLine", line);
    },
    markSpot( line)
    {
        this.$set( this, "updateLine", line);
    },
    dropFile($event, line, rowIdx, lineREF)
    {
      let that = this;
      event.preventDefault();
      event.stopPropagation();
      this.startLoader();
      this.showDropzone = false;
      const files = event.dataTransfer.files;
      const itemArray = [...files];
      
     
      const item = itemArray.find((i) => true);
      
      let check =  new Promise(function (resolve, reject) {
         //alert( asrAPI+"/checkAsRunFilename/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+item.name);
      	 HTTP.post( asrAPI+"/checkAsRunFilename/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+item.name)
	            .then( response => {
	            	that.stopLoader(); 
	            	resolve(response.data)
	            }).catch(err => {
	            that.stopLoader(); 
	            that.leaveLine(lineREF); 
	            showError( that.$toast, "File '"+item.name+"' not recognized", err)})
      	
      });
      
      check.then( info => { 
    	      let text = "Upload AsRunLog " + item.name;
	    	  if ( info.media )
	    	  {
			      that.stationId = info.media.id;
			      
			      that.station = info.media;
			      that.activeDate = new Date(info.txDate);
			      text = "Upload AsRunLog for "+info.media.shortname;
	    	  }
		      this.$refs.confirm.confirm( {text: 'Upload ' + item.name, title: text, button1: "Cancel", button2: "Upload"}).then(x => {
		       	  const formData = new FormData();
		       	  
		       	  that.leaveLine(lineREF);
			      formData.append('file', item);      
			      if (item) this.upload(formData, line, rowIdx, item.name, info.media);
		       }).catch(err => { 
		       		that.stopLoader();
		       		that.leaveLine(lineREF); 
		       });
	   });
    },
    async upload(data, line, rowIdx, name) {
      let uploadFile = await this.uploadFile(data, line, rowIdx, name);
    },
    uploadFile(formData, line, rowIdx, name, preload) 
    {
      //this.cancelTokenSource = axios.CancelToken.source();
      let that=this;
      this.startLoader();
      
      if ( preload )
      {
      	this.loadDay( this.selectedTimezone, new Date().getTime(), this.activeDate, this.stationId);
	  }
      HTTPMP.put( asrAPI+"/fileUpload/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId, formData)
        .then( response => 
        { 
            if ( response.data.txDate)
            {
        		that.stationId = response.data.media.id;
		   		that.station = response.data.media;
		    	that.activeDate = new Date(response.data.txDate);
		    	that.loadDay( this.selectedTimezone, new Date().getTime(), that.activeDate, that.stationId);
		    }
            //that.data = response.data;
            //that.data.lines = that.data.lines.filter( l => l.valid || l.durationMS > 1000*100 );
            that.$set( that, "data", response.data);
            that.$set( that.data, "refresh", new Date());
            that.stopLoader();
            that.$toast.success("File '"+name+"' uploaded to '"+that.station.name+"'", 'Ok', { timeout: 1500, position: "topRight" });
            if ( response.data.settings.autoReconcile )
            {
            	alert(that.stationId)
            	that.reco();
            }
            that.$forceUpdate();
      }).catch((err) => (showError( that.$toast, "File '"+name+"' not uploaded", err)))

    },
    hoverLine(line)
    {
    	$('#'+line).addClass('reqLineBG');
    },
    leaveLine(line)
    {
    	$('#'+line).removeClass('reqLineBG');
    },
    setTypeForSpots(spots, type, line)
	{
	    if ( spots.length > 1 )
	    {
	    	this.startLoader();
	    }
		var ids = new Array();
		for ( var i in spots )
		{
			ids.push( spots[i].id );
		}
		var bookRequest = {"statusId": type.id, "ids": ids, "starttimeMS": line?line.startTimeMS:null };
		
		if ( line && line.lineNo )
		{
			if ( this.data.fileId && this.data.meta )
			{
				if ( !this.data.meta.matched )
				{
					this.data.meta.matched = new Array();
				}
				//alert( type.airedValue+JSON.stringify(this.data.meta.matched));
				if ( type.airedValue==='aired' )
				{
					if ( !this.data.meta.matched.includes(line.lineNo) )
					{
						this.data.meta.matched.push(line.lineNo);
					}
				}
				else
				{
					this.data.meta.matched = this.data.meta.matched.filter(p=>p!=line.lineNo);
				}
				let that = this;
				HTTP.put( asrAPI+"/saveChanges/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.data.fileId, that.data.meta)
    		 		.then( response => {
    		 			that.$set( that, "data", that.data);
    		 		}).catch(e => {
	               
	                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });
			}	
		}
		let that = this;
		HTTP.put( bngAPI+"/rebook/asrun/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0/0", bookRequest)
	            .then( response => 
	            { 
	                that.updatedSpots = new Array();
	                if ( response.data.spots && response.data.spots.length )
	                {
		                that.updatedSpots = new Array();
		                that.$set( that, "updatedSpots", response.data.spots);
		                if ( spots.length > 1 )
		    			{
		                	that.$nextTick(function () {
		                		that.stopLoader(); 
		                 	})	;
		                 }
	                }
	                else
	                {
	                	that.reload();
	                }
	                
	                
	            }).catch(e => {
	                   that.stopLoader(); 
	                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
	            });
	},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    },
    stationChanged(  stationId) 
    {
       
        
    	this.loadAndCheckDay(this.activeDate, this.stationId, "/loadAndCheckDay");
    },
    dateChanged(newDate) {

    	this.activeDate = new Date( newDate);
    	//this.activeDateForWidget[ dayId] = newDate;
    	this.loadAndCheckDay(this.activeDate, this.stationId, "/loadAndCheckDay");
    },
    tzChanged(x) {
        this.timezoneId = x.id;
    	this.loadAndCheckDay(this.activeDate, this.stationId);
    },
    reload()
    {
    	this.loadAndCheckDay(this.activeDate, this.stationId, "/loadAndCheckDay");
    },
    reco()
    {
    	this.loadAndCheckDay(this.activeDate, this.stationId, "/reconcileDay");
    },
    loadAndCheckDay(activeDate, stationId, andAction)
    {
    	let that = this;
		if ( ! activeDate )
    	{
    		activeDate = this.activeDate;
    	}
        let dateStr = new Date(activeDate).toISOString().split('T')[0];
        if ( !stationId )
		{
			stationId = that.station.id;
		}
		this.startLoader();
   		//alert(asrAPI+andAction+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"//"+that.timezoneId+"/"+dateStr);
        HTTP.get( asrAPI+andAction+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"/"+that.tz.id+"/"+that.timezoneId+"/"+dateStr)
    		 .then( response => 
             { 
               	var data = response.data.intern;
               	that.bookingStatusList = data.bookingStatusList;
               	that.allStates=that.bookingStatusList.map( p=> { return {id: p.id, label: p.name}});
               	if ( response.data.extern )
               	{
               		that.$set( that, "data", response.data.extern);
               	}
               	else
               	{
               		that.$set( that, "data", {lines: []});
               	}
                that.$set( that.data, "refresh", new Date());
               	that.station = data.media;
               	that.user = data.user;
                that.columnsRD = that.user.spotListColumnsRD;
 				that.columnsCB = that.user.spotListColumnsCB;
               	for ( var iGrid in data.gridAds)
                {
                	let grid = data.gridAds[iGrid];
                	if ( grid )
                	{
	                	for ( var iBreak in grid.breaks)
	                	{
	                		let brk = grid.breaks[iBreak];
	                	
	                		if ( brk )
                			{
                			    if ( !that.allBreaks.includes(brk.type.shortname))
			                	{
			                		that.allBreaks.push(brk.type.shortname);
			                	}
                			    brk.unfilteredSpots = brk.spots;
		                		for ( var iSpot in brk.spots )
		                		{
		                		   
		                			let spot = brk.spots[iSpot];
		                			if ( !that.allPrograms.includes(spot.gridName))
				                	{
				                		that.allPrograms.push(spot.gridName);
				                	}
				                	if ( !that.allClients.includes(spot.clientName))
				                	{
				                		that.allClients.push(spot.clientName);
				                	}
				                	
				                	if ( !spot.productName )
				                	{
				                		spot.productName= "--";
				                	}
				                	if ( !that.allProducts.includes(spot.productName))
				                	{
				                		that.allProducts.push(spot.productName);
				                	}
				                	if ( !spot.copyName )
				                	{
				                		spot.copyName= "--";
				                	}
				                	if ( !that.allCopies.includes(spot.copyName))
				                	{
				                		that.allCopies.push(spot.copyName);
				                	}
		                		}
	                		}	
	                	}
	                }
                }
                
                that.dataDay = data;
				that.stopLoader();
                that.$forceUpdate();

             }).catch(e => {
                    that.stopLoader();
                    that.$toast.error("loading data for: "+andAction + e.response.data, 'Error', { position: "topRight" });
          		 });
    },
   
	processChange()
    {
     	this.doFilterSpots();
    },
    
	getRawKey(spot)
     {
    	return spot.gridId+":"+spot.mediaId+":"+spot.breakNo+":"+spot.breakTypeId+":"+spot.date;
     },
    deleteSpot( toDelete, fromId ) {
 
  	    let that = this;
  	    
		let time = new Date().getTime();
		let n = toDelete.length;
		var bookRequest = { "ids": toDelete };
  		console.log(bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
		//console.log( JSON.stringify(toDelete ));
		HTTP.put( bngAPI+"/deleteSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/0", bookRequest)
            .then( response => 
            { 
                let dur = (new Date().getTime()-time);
                
                console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
                that.reloadBreaks ( [fromId] );   
                
            }).catch(e => {
                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
  	},
    loadStation( stationId, activeDate )
    {
    	let autoLoad = false;
    	let that = this;
    	if ( !this.update )
    	{
    		this.update = new Array();
    	}
    	if ( !activeDate)
    	{
    		activeDate = this.activeDate;
    	}
    	else
    	{
    		autoLoad = true;
    	}
		//this.startLoader()

		that.selectedPrograms = new Array();
    	that.selectedCopies = new Array();
    	that.selectedClients = new Array();
    	that.selectedProducts = new Array();
    	that.selectedStates = new Array();
    	if ( this.selectedTimezone && this.selectedTimezone.id )
        {
       	 	this.timezoneId = this.selectedTimezone.id;
        }
        if ( ! stationId )
        {
            if ( this.stationId )
            {
        		stationId = this.stationId;
	        }
	        else
	        {
	        	if ( this.selectedStation && this.selectedStation.id )
	        	{
	       	 		stationId = this.selectedStation.id;
	        	}
	        }
	    }
        if ( !this.selectedTimezone  )
        {
       		this.timezoneId = 0;
        }
        
        let time = new Date().getTime();
    	let stationLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( fwAPI+"/getAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED STATIONS in " + dur +"ms");  
                    var allStations = response.data.data;
                    that.stations = [];
                    for ( var channel in allStations )
                    {
                    	if ( allStations[channel].visible )
                    	{
                    		that.stations.push( allStations[channel]);
                    		if ( allStations[channel].id == stationId || ! stationId)
	                    	{
	                    		that.selectedStation = allStations[channel];
	                    		that.stationId = that.stations[channel].id;
	                    		stationId = that.stations[channel].id;
	                    	}
                    	}
                    	
                    }    

                    resolve (stationId);                    
                 }).catch(e => {
                        that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
	      stationLoad.then( selectedStationId => {
	        let timezoneLoad = new Promise(function (resolve, reject) {
		   	    console.log(invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+selectedStationId+"/"+that.timezoneId);
		        HTTP.get( invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+selectedStationId+"/"+that.timezoneId)
	        		 .then( response => 
	                 {  
	                    let dur = (new Date().getTime()-time);
               		    console.log("LOADED STATION "+response.data.channel.shortname+" in " + dur +"ms");  
	                    that.station = response.data.channel;
						
	                    that.tz = response.data.timezone;
	                    that.tz2 = response.data.secondTimezone;
	                    
	                    that.timezones = [];//response.data.allTimezones;
	                    for ( var t in response.data.allTimezones)
	                    {
	                    	that.timezones.push( {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name});
	                    	if ( response.data.allTimezones[t].id == that.timezoneId )
	                    	{
	                    		that.selectedTimezone = {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name};
	                    		
	                    	}
	                    }
	                    if ( autoLoad )
	                    {
							that.loadAndCheckDay(activeDate, stationId, "/loadAndCheckDay");
						}
	                    resolve (response.data.timezone);
	                 }).catch(e => {
	                        that.$toast.error("loading data for timezones: " + e.response.data, 'Error', { position: "topRight" });
							reject ();
	                    });
	
	           });
	           timezoneLoad.then( timeZone => {
	           		
	           });
	           
	      });
    },
    statusUpdate( dateStr, channelId, state )
    {
    	let that = this;
    	for ( var idx in this.days)
		{
			let dayId = this.days[ idx];
			let data = this.dataSet[ dayId];
			//alert(data.date +"/"+dateStr+"/"+data.media.id +"/"+ channelId+ "/"+ JSON.stringify(state))
       		if ( data.date === dateStr && data.media.id === channelId )
   			{
   				data.status = state;
   				
   			}
       	}
    },
    changeState( dateStr, channelId, state )
    {
    	let that = this;
    	//alert(invAPI+"/setDayStatus/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+dateStr+"/"+state.id);
        HTTP.put( invAPI+"/setDayStatus/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+channelId+"/"+dateStr+"/"+state.id)
		 .then( response => 
         { 
            that.statusUpdate( dateStr, channelId, state )
           
          }).catch(e => {
            that.$toast.error("set day status " + e.response.data, 'Error', { position: "topRight" });

  		 });
    	
    },
    resetFilter() {
        let that = this;
        that.selectedPrograms = new Array();
    	that.selectedCopies = new Array();
    	that.selectedClients = new Array();
    	that.selectedProducts = new Array();
    	that.selectedStates = new Array();
    	//that.allPrograms = new Array();
    	//that.allCopies = new Array();
    	//that.allClients = new Array();
    	//that.allProducts = new Array();
    },
    
    userUpdated( user) { 
    		let that = this;
    		this.user = user  
    		console.log("User update")
			HTTP.put( userAPI+"/setUserSpotColumn/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.user)
            .then( response => 
            { 
                console.log( "CHANGED");
                console.log( that.user.spotColumns);
 				that.user = response.data;
 				that.columnsRD = that.user.spotListColumnsRD;
 				that.columnsCB = user.spotListColumnsCB;
            }).catch(e => {
                    alert(JSON.stringify(e));

            });
    },
    loadDay( timeZone, time, activeDate, stationId)
    {
    	let that = this;
		if ( ! activeDate )
    	{
    		activeDate = this.activeDate;
    	}
    	//that.allPrograms = new Array();
    	//that.allCopies = new Array();
    	//that.allClients = new Array();
    	//that.allProducts = new Array();
        let dateStr = new Date(activeDate).toISOString().split('T')[0];
        if ( !stationId )
		{
			stationId = that.station.id;
		}
		
   		//alert(invAPI+"/getInventoryForDay/asrun/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"/"+timeZone.id+"/"+that.timezoneId+"/"+dateStr);
        HTTP.get( invAPI+"/getInventoryForDay/asrun/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+stationId+"/"+timeZone.id+"/"+that.timezoneId+"/"+dateStr)
    		 .then( response => 
             { 
               	var data = response.data;
               	that.bookingStatusList = data.bookingStatusList;
               	that.allStates=that.bookingStatusList.map( p=> { return {id: p.id, label: p.name}});
               	that.station = data.media;
               	that.user = data.user;
                that.columnsRD = that.user.spotListColumnsRD;
 				that.columnsCB = that.user.spotListColumnsCB;
               	for ( var iGrid in data.gridAds)
                {
                	let grid = data.gridAds[iGrid];
                	if ( grid )
                	{
	                	for ( var iBreak in grid.breaks)
	                	{
	                		let brk = grid.breaks[iBreak];
	                	
	                		if ( brk )
                			{
                			    if ( !that.allBreaks.includes(brk.type.shortname))
			                	{
			                		that.allBreaks.push(brk.type.shortname);
			                	}
                			    brk.unfilteredSpots = brk.spots;
		                		for ( var iSpot in brk.spots )
		                		{
		                		   
		                			let spot = brk.spots[iSpot];
		                			if ( !that.allPrograms.includes(spot.gridName))
				                	{
				                		that.allPrograms.push(spot.gridName);
				                	}
				                	if ( !that.allClients.includes(spot.clientName))
				                	{
				                		that.allClients.push(spot.clientName);
				                	}
				                	
				                	if ( !spot.productName )
				                	{
				                		spot.productName= "--";
				                	}
				                	if ( !that.allProducts.includes(spot.productName))
				                	{
				                		that.allProducts.push(spot.productName);
				                	}
				                	if ( !spot.copyName )
				                	{
				                		spot.copyName= "--";
				                	}
				                	if ( !that.allCopies.includes(spot.copyName))
				                	{
				                		that.allCopies.push(spot.copyName);
				                	}
		                		}
	                		}	
	                	}
	                }
                }
                
                that.dataDay = data;

                that.$forceUpdate();
                let dur = (new Date().getTime()-time);
               	console.log("LOADED DAY "+dateStr+", "+ that.station.id +" in " + dur +"ms");
             }).catch(e => {
                    that.$toast.error("loading data for timezones: " + e.response.data, 'Error', { position: "topRight" });
          		 });
	         
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loadStation();
		this.loader.hide();
	}
  },
  created() { 
    
    this.activeDate = new Date();
    this.timezoneId = sessionStorage.timezoneId;
    if ( this.$route.query.stationId && this.$route.query.day )
    {
    	this.activeDate = new Date(this.$route.query.day);
	  	this.loadStation( this.$route.query.stationId, this.activeDate);
    }
	else
	{
		this.loadStation();
	}
    initAppMode();
  },
  watch: {
   selectedStates: function() {
	    
		
	},
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 82%; 
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.header {
  font-size: 14pt;
  font-weight: bold;
}
.reqLineBG2 {
    cursor: pointer;
    color: #00a;
	background-color: #fcf !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
</style> 